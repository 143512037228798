import difference from 'lodash/difference';
import { MimeTypeItemType, Objkt, ObjktType } from '~/types';

export const MIMETYPE = {
  // AUDIO
  BMP: 'image/bmp',
  GIF: 'image/gif',
  JPEG: 'image/jpeg',
  PNG: 'image/png',
  SVG: 'image/svg+xml',
  TIFF: 'image/tiff',
  WEBP: 'image/webp',
  // VIDEO
  MP4: 'video/mp4',
  OGV: 'video/ogg',
  QUICKTIME: 'video/quicktime',
  WEBM: 'video/webm',
  // AUDIO
  MP3: 'audio/mpeg',
  OGA: 'audio/ogg',
  WAV: 'audio/wav',
  XWAV: 'audio/x-wav',
  FLAC: 'audio/flac',
  // APPLICATION
  DIRECTORY: 'application/x-directory',
  // OTHER
  GLB: 'model/gltf-binary',
  GLTF: 'model/gltf+json',
  PDF: 'application/pdf',
  ZIP: 'application/zip',
  ZIP1: 'application/x-zip-compressed',
  ZIP2: 'multipart/x-zip',
  MD: 'text/plain',
};

export const IPFS_DIRECTORY_MIMETYPE = 'application/x-directory';

export const IMAGE_MIMETYPES = [
  MIMETYPE.BMP,
  MIMETYPE.GIF,
  MIMETYPE.JPEG,
  MIMETYPE.PNG,
  MIMETYPE.TIFF,
  MIMETYPE.WEBP,
];

export const VIDEO_MIMETYPES = [
  MIMETYPE.MP4,
  MIMETYPE.OGV,
  MIMETYPE.QUICKTIME,
  MIMETYPE.WEBM,
];

export const AUDIO_MIMETYPES = [
  MIMETYPE.MP3,
  MIMETYPE.OGA,
  MIMETYPE.WAV,
  MIMETYPE.XWAV,
  MIMETYPE.FLAC,
];

export const INTERACTIVE_MIMETYPES = [
  MIMETYPE.DIRECTORY,
];

export const OTHER_MIMETYPES = difference(Object.values(MIMETYPE), [
  ...IMAGE_MIMETYPES,
  ...VIDEO_MIMETYPES,
  ...AUDIO_MIMETYPES,
  ...INTERACTIVE_MIMETYPES,
]);

export const isAudioObjkt = (objkt: Objkt) => AUDIO_MIMETYPES.includes(objkt.mimeType);

export const getObjktType = (mimeType: string): ObjktType => {
  if (AUDIO_MIMETYPES.includes(mimeType)) return 'audio';
  if (VIDEO_MIMETYPES.includes(mimeType)) return 'video';
  if (IMAGE_MIMETYPES.includes(mimeType)) return 'image';
  if (INTERACTIVE_MIMETYPES.includes(mimeType)) return 'interactive';
  return 'other';
};

export const objktIsOfType = (objkt: Objkt, type: ObjktType): boolean => getObjktType(objkt.mimeType) === type;

export const MIMETYPE_LIST: MimeTypeItemType[] = [
  {
    label: 'Image',
    value: 'image',
    types: IMAGE_MIMETYPES,
  },
  {
    label: 'Video',
    value: 'video',
    types: VIDEO_MIMETYPES,
  },
  {
    label: 'Audio',
    value: 'audio',
    types: AUDIO_MIMETYPES,
  },
  {
    label: 'Interactive',
    value: 'interactive',
    types: INTERACTIVE_MIMETYPES,
  },
  {
    label: 'Other',
    value: 'other',
    types: OTHER_MIMETYPES,
  },
];
