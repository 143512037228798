/* eslint-disable @typescript-eslint/naming-convention */
import minBy from 'lodash/minBy';
import maxBy from 'lodash/maxBy';
import orderBy from 'lodash/orderBy';
import sumBy from 'lodash/sumBy';
import { meanBy } from 'lodash';
import { Objkt, Swap, User } from '~/types';
import { replaceIPFS, filterNonNull, shortenAddress } from '~/utils';
import { DIVIDER } from '~/utils/const';
import { useDataContext } from '~/contexts/Data';
import { ADDRESSES } from '~/utils/addresses';
import useCurrentUserAddress from '~/hooks/useCurrentUserAddress';

export const userFragment = `
  name
  address
  description
  metadata
  metadata_file
  __typename
`;

export const tokenFragmentXLite = `
  id
  title
  description
  display_uri
  artifact_uri
  thumbnail_uri
  mime
  supply
  metadata
  __typename
  creator {
    ${userFragment}
  }
`;

export const tokenFragmentLite = `
  ${tokenFragmentXLite}
  timestamp
  royalties
  swaps {
    id
    timestamp
    amount
    amount_left
    price
    contract_version
    status
    royalties
    creator_id
    is_valid
    ophash
    creator {
      ${userFragment}
    }
    __typename
  }
  trades(order_by: { timestamp: asc }) {
    id
    timestamp
    ophash
    amount
    swap {
      price
    }
    seller {
      ${userFragment}
    }
    buyer {
      ${userFragment}
    }
    __typename
  }
  token_holders(
    where: {
      quantity: {
        _gt: 0
      },
      holder_id: {
        _nin: [
          ${ADDRESSES.burn}
        ]
      }
    }
  ) {
    holder_id
    quantity
    holder {
      ${userFragment}
    }
    __typename
  }
`;

export const tokenFragment = `
  ${tokenFragmentLite}
  token_tags {
    tag {
      tag
    }
  }
`;

export const isValidSwap = ({
  contract_version,
  status,
  is_valid,
}: Swap) => parseInt(contract_version, 10) === 2 && parseInt(status, 10) === 0 && is_valid;

export const transformToken = (
  token: Objkt,
  blockedWallets: string[] = [],
  currentUserAddress?: string,
): Objkt => {
  const {
    id = null,
    title = '',
    mime = null,
    display_uri = null,
    thumbnail_uri = null,
    artifact_uri = null,
    token_tags = [],
    token_holders = [],
    swaps = [],
    supply = null,
    creator = {} as User,
    trades = [],
  } = token;
  const editions = token_holders.filter(
    (e) => e.holder_id === ADDRESSES.v2,
  ).length > 0
    ? token_holders.filter(
      (e) => e.holder_id === ADDRESSES.v2,
    )[0].quantity
    : 0;
  const validSwaps = orderBy(
    swaps.filter(isValidSwap),
    [(o) => +o.price],
    ['asc'],
  );
  const forSaleCount = sumBy(validSwaps, (o) => +o.amount_left);
  const isFromBlockedWallet = blockedWallets.includes?.(creator?.address);
  const minSwap = minBy(validSwaps, (o) => Number(o.price));
  const minPrice = (minSwap?.price || 0) / DIVIDER || 0;
  const maxSwap = maxBy(validSwaps, (o) => Number(o.price));
  const maxPrice = (maxSwap?.price || 0) / DIVIDER || 0;
  const firstTrade = orderBy(trades, ({ timestamp }) => timestamp)[0];
  const lastTrade = orderBy(trades, ({ timestamp }) => timestamp)[trades.length - 1];
  const firstPrice = firstTrade?.swap?.price / DIVIDER;
  const lastPrice = lastTrade?.swap?.price / DIVIDER;
  const collectPrices = currentUserAddress
    ? trades
      .filter(Boolean)
      .filter(({ buyer }) => buyer && buyer.address === currentUserAddress)
      .map((x) => x)
    : [];
  const averageCollectPrice = collectPrices.length > 0 ? meanBy(collectPrices, 'swap.price') / DIVIDER : null;
  const objkt = filterNonNull({
    ...token,
    title: title || (id && `objkt #${id}`),
    assetUrl: replaceIPFS(artifact_uri),
    imageUrl: replaceIPFS(display_uri || artifact_uri || thumbnail_uri),
    mimeType: mime,
    link: `https://hen-mirror.hic.af/objkt/${id}`,
    tags: token_tags.map(({ tag }) => tag.tag).filter(Boolean),
    editions,
    supply,
    minSwap,
    minPrice,
    maxPrice,
    validSwaps,
    isSecondarySale: minSwap?.creator?.address !== token?.creator?.address,
    isForSale: minSwap?.amount_left > 0,
    isFromBlockedWallet,
    token_holders: token_holders.filter(
      (e) => e.holder_id !== ADDRESSES.v2,
    ),
    forSaleCount,
    firstTrade,
    lastTrade,
    firstPrice,
    lastPrice,
    collectPrices,
    averageCollectPrice,
  }) as Objkt;
  return objkt;
};

export const useTransformToken = () => {
  const { blockedWallets } = useDataContext();
  const address = useCurrentUserAddress();
  return (token: Objkt) => transformToken(token, blockedWallets, address);
};

export const useTransformUser = () => {
  const { blockedWallets } = useDataContext();
  return (user: User): User => ({
    ...user,
    isBlocked: blockedWallets.includes(user.address),
  });
};

export const getUserLink = ({
  name = null,
  address = null,
}: {
  name: string;
  address: string;
} = {
  name: null,
  address: null,
}) => (!name && !address ? '' : name ? `/${encodeURIComponent(name)}` : `/tz/${address}`);

export const getUserLinkLabel = ({
  name = null,
  address = null,
}: {
  name: string;
  address: string;
} = {
  name: null,
  address: null,
}) => (!name && !address ? '' : name ? `/${encodeURIComponent(name)}` : `/tz/${shortenAddress(address)}`);
