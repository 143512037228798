import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import styled from 'styled-components';

type Props = {
  $animating?: boolean;
  $active?: boolean;
};

const LoadIcon = styled(AiOutlineLoading3Quarters)<Props>`
  transform-origin: center;
  transition: color 300ms ease;
  color: ${({ theme, $active }) => ($active ? theme.colors.text : theme.colors.textLight)};
  @media (hover: hover) {
    &:hover {
      color: ${({ theme }) => theme.colors.text};
    }
  }
  @keyframes fa-spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
  ${({ $animating }) => ($animating ? `
    animation: fa-spin 1500ms linear infinite;
  ` : null)}
`;

export default LoadIcon;
