export const ADDRESSES = {
  hicaf: 'tz1TPh8yGoBbT5Pg76mH1MUZACZsVMRnJLKB',
  // smart contracts
  hDAO: 'KT1AFA2mwNUMNd4SsujE1YYp29vd8BZejyKW',
  subjkt: 'KT1My1wDZHDGweCrJnQJi3wcFaS67iksirvj',
  v1: 'KT1Hkg5qeNhfwpKW4fXvq7HGZB9z2EnmCCA9', // swap
  v2: 'KT1HbQepzV1nVGg8QVznG7z4RcHseD5kwqBn', // swap
  // v2: 'KT1Bgqn49ksQiZbDie8cFefUxhibyzZ62zaX', // HAF SWAP
  objkts: 'KT1RJ6PbjHpwc3M5rw5s2Nbmefwbuwbdxton', // mint, transfer, burn, etc
  unregistry: 'KT18xby6bb1ur1dKe7i6YVrBaksP4AgtuLES',
  hDAO_curation: 'KT1TybhR7XraG75JFYKSrh7KnxukMBT5dor6',
  hDAO_marketplace: 'KT1QPvv7sWVaT9PcPiC4fN9BgfX8NB2d5WzL',
  burn: 'tz1burnburnburnburnburnburnburjAYjjX',
};
