import React from 'react';
import { Objkt } from '~/types';
import { isAudioObjkt } from '~/utils/mime';

type AudioPlayerData = {
  objkt: Objkt | null;
  playlist: Objkt[];
  setPlaylist: (p: Objkt[]) => void;
  trackProgress: number;
  duration: number;
  load: (objkt: Objkt) => void;
  play: (objkt: Objkt) => void;
  pause: () => void;
  close: () => void;
  isPlaying: boolean;
  isLoading: boolean;
  isPaused: boolean;
  onScrub: (ts: number) => void;
  onScrubEnd: () => void;
  goToPreviousTrack: () => void;
  goToNextTrack: () => void;
};

const defaultAudioPlayerData = {
  objkt: null,
  playlist: [],
  setPlaylist: () => null,
  trackProgress: 0,
  duration: 0,
  load: () => null,
  play: () => null,
  pause: () => null,
  close: () => null,
  isPlaying: false,
  isLoading: false,
  isPaused: false,
  onScrub: () => null,
  onScrubEnd: () => null,
  goToPreviousTrack: () => null,
  goToNextTrack: () => null,
};

const AudioPlayerContext = React.createContext<AudioPlayerData>(defaultAudioPlayerData);

export const AudioPlayerProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [objkt, setObjkt] = React.useState<Objkt>();
  const [playlist, setPlaylist] = React.useState<Objkt[]>([]);
  const [playlistTemp, setPlaylistTemp] = React.useState<Objkt[]>([]);
  const [trackProgress, setTrackProgress] = React.useState<number>(0);
  const intervalRef = React.useRef<NodeJS.Timeout>();
  const [isPlaying, setIsPlaying] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isPaused, setIsPaused] = React.useState<boolean>(false);
  const audioRef = React.useRef<HTMLAudioElement>();
  const { duration } = audioRef.current || {};
  const load = (o: Objkt) => {
    const preloaded = new Audio(o.assetUrl);
    preloaded.preload = 'auto';
  };
  const play = (o_?: Objkt) => {
    if (isPaused) {
      setIsPlaying(true);
      setIsPaused(false);
    }
    setObjkt(o_ || objkt);
    setPlaylist(playlistTemp);
  };
  const pause = () => {
    setIsPaused(true);
    setIsPlaying(false);
  };
  const close = () => {
    setObjkt(null);
    setIsPaused(false);
    setIsPlaying(false);
    setIsLoading(false);
  };
  const goToPreviousTrack = () => {
    const index = playlist.findIndex((t) => t.id === objkt?.id);
    const previousTrack = playlist[index - 1] || playlist[0];
    if (previousTrack) setObjkt(previousTrack);
  };
  const goToNextTrack = React.useCallback(() => {
    const index = playlist.findIndex((t) => t.id === objkt?.id);
    const nextTrack = playlist[index + 1] || playlist[playlist.length - 1];
    if (nextTrack) setObjkt(nextTrack);
  }, [JSON.stringify(playlist), objkt, play]);
  const startTimer = React.useCallback(() => {
    clearInterval(intervalRef.current);
    setTrackProgress(audioRef.current?.currentTime || 0);
    intervalRef.current = setInterval(() => {
      setTrackProgress(audioRef.current?.currentTime);
      if (audioRef.current?.ended) {
        clearInterval(intervalRef.current);
        goToNextTrack();
        setIsPaused(false);
        setIsPlaying(false);
        setIsLoading(false);
      }
    }, 1000);
  }, [goToNextTrack]);
  const onScrub = (value) => {
    // Clear any timers already running
    clearInterval(intervalRef.current);
    audioRef.current.currentTime = value;
    setTrackProgress(audioRef.current.currentTime);
  };
  const onScrubEnd = () => {
  // If not already playing, start
    if (!isPlaying) {
      setIsPlaying(true);
    }
    startTimer();
  };
  React.useEffect(() => {
    if (isPlaying) {
      audioRef.current?.play();
      startTimer();
    } else {
      clearInterval(intervalRef.current);
      audioRef.current?.pause();
    }
  }, [isPlaying, startTimer]);
  React.useEffect(() => {
    if (objkt) {
      setTrackProgress(0);
      setIsPlaying(false);
      setIsLoading(true);
      audioRef.current?.pause();
      audioRef.current = new Audio(objkt.assetUrl);
      audioRef.current.addEventListener('canplaythrough', () => {
        setIsLoading(false);
        setIsPlaying(true);
      }, false);
    }
  }, [objkt]);
  React.useEffect(() => () => {
    audioRef.current?.pause();
  }, []);
  const setPlaylistWithFilter = React.useCallback(
    (p: Objkt[]) => setPlaylistTemp(p.filter(isAudioObjkt)),
    [],
  );
  return (
    <>
      <AudioPlayerContext.Provider value={
        {
          objkt,
          playlist,
          setPlaylist: setPlaylistWithFilter,
          trackProgress,
          duration,
          load,
          play,
          pause,
          close,
          isPlaying,
          isLoading,
          isPaused,
          onScrub,
          onScrubEnd,
          goToPreviousTrack,
          goToNextTrack,
        }
      }
      >
        {children}
      </AudioPlayerContext.Provider>
    </>
  );
};

// eslint-disable-next-line import/no-unused-modules
export const useAudioPlayer = () => React.useContext(AudioPlayerContext);
